import IconList from "./IconList";
import Taskbar from "./Taskbar";
import Window from "./Window";
import AboutWindow from "./AboutWindow";
import OurWorksWindow from "./OurWorksWindow";
import Arrays from "../util/Arrays";
import ProjectWindow from "./ProjectWindow";
import MailUsWindow from "./MailUsWindow";

// Private fields
let _root = Symbol("root");
let _taskbar = Symbol("taskbar");
let _iconList = Symbol("iconList");
let _windows = Symbol("windows");
let _projectWindows = Symbol("projectWindows");
let _autoPosition = Symbol("autoPosition");

export default class Desktop {
	
	/**
	 * 
	 * @param {HTMLElement | JQuery<HTMLElement>} element 
	 */
	constructor(element) {
		element = $(element);
		this[_root] = $(element);
		this[_windows] = [];
		this[_projectWindows] = new Map();
		
		this[_iconList] = new IconList(element.find(".icon-layer"));
		let aboutWindow = null;
		let ourWorksWindow = null;
		let mailUsWindow = null;

		this[_iconList].on("about", () => {
			if (aboutWindow !== null) {
				aboutWindow.activate();
				return;
			}

			aboutWindow = new AboutWindow($("#desktop"));
			this.addWindow(aboutWindow);
			aboutWindow.on("remove", () => aboutWindow = null);
		});

		this[_iconList].on("ourWorks", () => {
			if (ourWorksWindow !== null) {
				ourWorksWindow.activate();
				return;
			}

			ourWorksWindow = new OurWorksWindow($("#desktop"));
			this.addWindow(ourWorksWindow);
			ourWorksWindow.on("remove", () => ourWorksWindow = null);
			ourWorksWindow.on("showProject", projectName => this.showProject(projectName));
		});

		this[_iconList].on("mailUs", () => {
			if (mailUsWindow !== null) {
				mailUsWindow.activate();
				return;
			}

			mailUsWindow = new MailUsWindow($("#desktop"));
			this.addWindow(mailUsWindow);
			mailUsWindow.on("remove", () => mailUsWindow = null);
		});

		this[_taskbar] = new Taskbar($("#taskbar"));

		// Translate button handler;
		this[_taskbar].on("translate", () => {
			if (getLanguage() === "ru") {
				setLanguage("en");
			} else {
				setLanguage("ru");
			}

			this.translate();
		});

		this.translate();
	}

	/**
	 * 
	 * @param {string} projectName 
	 */
	showProject(projectName) {
		/**
		 * @type Map.<string, ProjectWindow>
		 */
		let projectWindows = this[_projectWindows];
		let projectWindow = projectWindows.get(projectName);
		
		if (projectWindow) {
			projectWindow.activate();
			return;
		}

		projectWindow = new ProjectWindow($("#desktop"), projectName);
		projectWindows.set(projectName, projectWindow);
		this.addWindow(projectWindow);
		projectWindow.on("remove", () => projectWindows.delete(projectName));
	}

	/**
	 * 
	 * @param {Window} wnd 
	 */
	addWindow(wnd) {
		/**
		 * @type Array.<Window>
		 */
		let windows = this[_windows];

		wnd.on("activate", () => {
			if (windows[windows.length - 1] === wnd) {
				return;
			}

			Arrays.remove(windows, wnd);
			windows.push(wnd);
			this.orderWindows();
		});

		wnd.on("remove", () => {
			Arrays.remove(windows, wnd);
			this.orderWindows();
		});

		// Autopositioning support
		// Determine this window's autoPosition counter as the first unused auto position
		let usedAutoPositions = new Set();

		// Loop through all windows the user hasn't moved yet
		for (let otherWnd of windows) {
			let autoPosition = otherWnd[_autoPosition];

			if (autoPosition !== 0) {
				usedAutoPositions.add(autoPosition);
			}
		}

		for (let i = 1; ; i++) {
			if (!usedAutoPositions.has(i)) {
				wnd[_autoPosition] = i;
				break;
			}
		}

		// Position window
		wnd.show();
		let position = wnd.getPosition(); // Default window position
		let offset = wnd.getHeaderHeight() * (wnd[_autoPosition] - 1);
		wnd.setPosition({ left: position.left + offset, top : position.top + offset });

		// On first drag, clear autoPosition counter
		let onDragStart = () => {
			wnd[_autoPosition] = 0;
			wnd.off("dragstart", onDragStart);
		};
		wnd.on("dragstart", onDragStart);

		wnd.onTranslate(getLanguage());

		windows.push(wnd);
		this.orderWindows();
		
	}

	/**
	 * Sets z-index of windows and marks the topmost window as active.
	 */
	orderWindows() {
		/**
		 * @type Array.<Window>
		 */
		let windows = this[_windows];

		for (let i = 0; i < windows.length; i++) {
			let element = windows[i].getElement();
			element.css("z-index", i + 10);

			if (i < windows.length - 1) {
				element.addClass("window-inactive");
				element.removeClass("window-active");
			} else {
				element.removeClass("window-inactive");
				element.addClass("window-active");
			}
		}
	}
	
	/**
	 * Translates the icons, taskbar, and windows into the user-selected language.
	 */
	translate() {
		let language = getLanguage();

		this[_iconList].onTranslate(language);
		this[_taskbar].onTranslate(language);

		for (let wnd of this[_windows]) {
			wnd.onTranslate(language);
		}
	}
}

/**
 * @return {string}
 */
function getLanguage() {
	let language = localStorage.language || navigator.language.substring(2);

	if (language !== "ru") {
		return "en";
	}

	return "ru";
}

/**
 * 
 * @param {string} language 
 */
function setLanguage(language) {
	localStorage.language = language;
}
