import Window from "./Window";

import contentHtml from "../templates/MailUsWindow-content.html";

import en from "../texts/MailUsWindow-en.json";
import ru from "../texts/MailUsWindow-ru.json";

const LOCALE_DATA = { en : en, ru : ru };
const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

let _inputs = Symbol("inputs");
let _sendButton = Symbol("sendButton");

export default class MailUsWindow extends Window {
	/**
	 * 
	 * @param {HTMLElement | JQuery<HTMLElement>} parentElement 
	 */
	constructor(parentElement) {
		super(parentElement, LOCALE_DATA);
		this.setContentHtml(contentHtml);

		let el = this.getElement();
		el.addClass("mail-us-window");

		let sendButton = el.find(".send-button");
		this[_sendButton] = sendButton;

		let from = el.find(".mail-us-from");
		let subject = el.find(".mail-us-subject");
		let body = el.find(".mail-us-body-area textarea");
		let inputs = [ from, subject, body ];
		this[_inputs] = inputs;

		for (let input of inputs) {
			input.on("input", () => {
				let sendEnabled = inputs.every(it => it.val().length > 0) && EMAIL_REGEX.test(from.val());
				sendButton.prop("disabled", !sendEnabled);
			});
		}

		sendButton.click(() => {
			this.sendMessage({ from : from.val(), subject : subject.val(), body : body.val() });
		});
	}

	sendMessage(messageInfo) {
		this[_sendButton].prop("disabled", true);
		this[_sendButton].addClass("loading");
		this[_inputs].forEach(input => input.prop("disabled", true));

		let statusBar = this.getElement().find(".mail-us-status-bar");
		let localeData = LOCALE_DATA[this.getElement().attr("lang")];

		// Send request to server
		$.ajax("/api/send", {
			method: "POST",
			data: JSON.stringify(messageInfo),
			contentType: "application/json"
		}).then(() => {
			// Success
			this[_sendButton].prop("disabled", false);
			this[_sendButton].removeClass("loading");

			for (let input of this[_inputs]) {
				input.prop("disabled", false);
				input.val("");
			}

			statusBar.text(localeData["message-sent"]);
			statusBar.show();
		}, (jqxhr, textStatus, errorThrown) => {
			// Failure
			this[_sendButton].prop("disabled", false);
			this[_sendButton].removeClass("loading");

			for (let input of this[_inputs]) {
				input.prop("disabled", false);
			}

			statusBar.text(localeData["error"].replace("%s", errorThrown));
			statusBar.show();
		});
	}
}