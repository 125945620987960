let _handlerMap = Symbol("handlerMap");

/**
 * Base class for classes that need to support event handlers.
 */
export default class EventDispatcher {
	constructor() {
		this[_handlerMap] = new Map();
	}

	/**
	 * Adds an event handler for the given event name.
	 * 
	 * @param {string} eventName event name
	 * @param {Function} handler event handler function
	 */
	on(eventName, handler) {
		/**
		 * @type {Map}
		 */
		let handlerMap = this[_handlerMap];
		
		if (!handlerMap.has(eventName)) {
			handlerMap.set(eventName, new Set());
		}

		/**
		 * @type Set.<Function>
		 */
		let handlers = handlerMap.get(eventName);
		handlers.add(handler);
	}

	/**
	 * Removes an event handler for the given event name. If `handler` is not set, removes ALL event handlers
	 * for this event name.
	 * 
	 * @param {string} eventName event name
	 * @param {Function=} handler event handler function (optional)
	 */
	off(eventName, handler) {
		/**
		 * @type {Map}
		 */
		let handlerMap = this[_handlerMap];
		
		if (!handlerMap.has(eventName)) {
			return;
		}

		if (!handler) {
			handlerMap.delete(eventName);
		}

		/**
		 * @type Set.<Function>
		 */
		let handlers = handlerMap.get(eventName);
		handlers.delete(handler);
	}

	/**
	 * Triggers the given event. Any additional arguments are passed to event handlers.
	 * 
	 * @param {string} eventName event name
	 * @param {...*} eventArgs event arguments (passed to event handlers)
	 */
	trigger(eventName, ...eventArgs) {
		/**
		 * @type {Map}
		 */
		let handlerMap = this[_handlerMap];
		
		if (!handlerMap.has(eventName)) {
			return;
		}

		for (let handler of handlerMap.get(eventName)) {
			handler.apply(this, eventArgs);
		}
	}
}
