import View from "./View";

import en from "../texts/Taskbar-en.json";
import ru from "../texts/Taskbar-ru.json";

export default class Taskbar extends View {
	/**
	 * 
	 * @param {HTMLElement | JQuery<HTMLElement>} element 
	 */
	constructor(element) {
		super(element, { en : en, ru : ru });
		updateClock();
		
		let date = new Date();
		// number of milliseconds that will pass before
		// the start of next minute, plus 10 extra msec
		let startOfNextMinute = 60 * 1000
			- (date.getSeconds() * 1000 + date.getMilliseconds())
			+ 10;
		setTimeout(startClock, startOfNextMinute);

		$("#translatebutton").click(() => {
			this.trigger("translate");
		});
	}
}

function startClock() {
	updateClock();
	setInterval(updateClock, 1000 * 60); // once per minute
}

function updateClock() {
	let date = new Date();
	let hours = date.getHours();
	if (hours < 10) hours = '0' + hours;
	
	let minutes = date.getMinutes();
	if (minutes < 10) minutes = '0' + minutes;
	
	$("#clock").text(hours + ":" + minutes);
}
