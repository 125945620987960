import View from "./View";
import html from "../templates/Window.html";

export default class Window extends View {
	/**
	 * Creates a new window. It is not initially attached to the DOM.
	 * 
	 * @param {HTMLElement | JQuery<HTMLElement>} parentElement
	 * @param {object} localeData 
	 */
	constructor(parentElement, localeData) {
		super($(html), localeData);
		this.addTo(parentElement);

		let element = this.getElement();
		element.resizable({ containment: parentElement });
		element.draggable({
			handle: ".window-header",
			containment: parentElement,
			start: () => {
				this.trigger("dragstart");
			}
		});

		element.click(e => {
			this.activate();
		});

		element.find(".close-window").click(e => {
			this.remove();
		});
	}

	/**
	 * Brings this window to the foreground.
	 */
	activate() {
		this.trigger("activate");
	}

	/**
	 * Sets the window content to the specified HTML markup.
	 * 
	 * @param {string} html 
	 */
	setContentHtml(html) {
		this.getElement().find(".window-content").html(html);
	}

	/**
	 * Returns the height of the window header.
	 */
	getHeaderHeight() {
		return this.getElement().find(".window-header").outerHeight();
	}

	/**
	 * Gets the window position.
	 * 
	 * @return {JQueryCoordinates}
	 */
	getPosition() {
		return this.getElement().position();
	}

	/**
	 * Sets the window position.
	 * 
	 * @param {JQueryCoordinates} position
	 */
	setPosition(position) {
		this.getElement().css({
			top: position.top + "px",
			left: position.left + "px"
		});

		constrain(this.getElement());
	}

	/**
	 * Sets the window size in any units supported by CSS. If `width` and/or `height` is numeric, pixels are used.
	 * 
	 * @param {number | string} width 
	 * @param {number | string} height 
	 */
	setSize(width, height) {
		let element = this.getElement();
		element.width(width);
		element.height(height);
		constrain(this.getElement());
	}
	
	/**
	 * Sets the data-locale attribute of the window header to the specified string.
	 * 
	 * @param {string} key 
	 */
	setHeaderTranslationKey(key) {
		this.getElement().find(".header-text").attr("data-locale", key);
	}
}

/**
 * 
 * @param {JQuery<HTMLElement>} element
 */
function constrain(element) {
	let parent = element.parent();
	let position = element.position();
	
	if (position.left + element.outerWidth() > parent.outerWidth()) {
		element.css("left", (parent.outerWidth() - element.outerWidth()) + "px");
	}

	if (position.top + element.outerHeight() > parent.outerHeight()) {
		element.css("top", (parent.outerHeight() - element.outerHeight()) + "px");
	}
}
