import projects from "../texts/projects.json";

/**
 * Implementation of project view logic shared between ProjectView and ProjectWindow.
 */
export default {
	/**
	 * @param {JQuery<HTMLElement>} element
	 * @param {string} projectName
	 */
	fill: function(element, projectName) {
		let project = projects[projectName];
		element.find(".project-window-site-link").attr("href", project.link);

		// Add images to image area
		let origImageElement = element.find(".project-window-image");
		let imageElements = [];
		
		for (let i = 0; i < project.images.length; i++) {
			let imageName = project.images[i];
			let imageElement;

			if (i == 0) {
				imageElement = origImageElement;
			} else {
				imageElement = origImageElement.clone();
				origImageElement.parent().append(imageElement);
				imageElement.hide();
			}

			imageElement.find("img").attr("src", "/images/" + projectName + "/" + imageName);
			imageElements.push(imageElement);
		}

		// Left/right button handlers
		let currentImageIndex = 0;
		let maxImageIndex = project.images.length - 1;
		let prevButton = element.find(".project-window-prev-image");
		let nextButton = element.find(".project-window-next-image");

		prevButton.prop("disabled", true);
		prevButton.click(() => {
			imageElements[currentImageIndex].hide();
			currentImageIndex--;

			if (currentImageIndex == 0) {
				prevButton.prop("disabled", true);
			}

			nextButton.prop("disabled", false);
			imageElements[currentImageIndex].show();
		});

		nextButton.click(() => {
			imageElements[currentImageIndex].hide();
			currentImageIndex++;

			if (currentImageIndex == maxImageIndex) {
				nextButton.prop("disabled", true);
			}

			prevButton.prop("disabled", false);
			imageElements[currentImageIndex].show();
		});
	}
}