import Window from "./Window";
import contentHtml from "../templates/AboutWindow-content.html";

import en from "../texts/AboutWindow-en.json";
import ru from "../texts/AboutWindow-ru.json";

export default class AboutWindow extends Window {
	/**
	 * Creates a new about window. It is not initially attached to the DOM.
	 * 
	 * @param {HTMLElement} parentElement
	 */
	constructor(parentElement) {
		super(parentElement, { en : en, ru : ru });
		this.getElement().addClass("about-window");
		this.setContentHtml(contentHtml);
		this.setSize(screen.width / 2, screen.height / 2);

		let that = this;

		$(".about-window-panel a").each(function() {
			that.slowScroll(this);
		});
	}

	slowScroll(menuItem) {
		let content = this.getElement().find(".about-window-content");

		$(menuItem).click(() => {
			let scrollToClassName = $(menuItem).attr("data-anchor");
			let scrollTo = content.find("." + scrollToClassName);

			if (scrollTo.length != 0) {
				content.animate({ scrollTop: scrollTo[0].offsetTop }, 500);
			}

			return false;
		});
	}
}
