import Window from "./Window";
import projects from "../texts/projects.json";
import contentHtml from "../templates/ProjectWindow-content.html";
import ProjectContents from "./ProjectContents";

export default class ProjectWindow extends Window {
	/**
	 * 
	 * @param {HTMLElement | JQuery<HTMLElement>} parentElement 
	 * @param {string} projectName 
	 */
	constructor(parentElement, projectName) {
		let project = projects[projectName];
		super(parentElement, { en : project.en, ru : project.ru });

		// Window adjustments
		this.setHeaderTranslationKey("name");
		this.getElement().addClass("project-window");

		// Content
		this.setContentHtml(contentHtml);
		ProjectContents.fill(this.getElement(), projectName);
	}
}