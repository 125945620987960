import View from "./View";

import en from "../texts/IconList-en.json";
import ru from "../texts/IconList-ru.json";

export default class IconList extends View {
	constructor(element) {
		super(element, { en : en, ru : ru });

		// Initialize icons
		$("#about-icon").click(e => {
			this.trigger("about");
		});

		$("#our-works-icon").click(e => {
			this.trigger("ourWorks");
		});

		$("#mail-us-icon").click(e => {
			this.trigger("mailUs");
		});
	}
}
