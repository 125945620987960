export default {
	/**
	 * Removes all elements from `array` that are equal to `element`.
	 * 
	 * @param {Array} array
	 * @param {*} element
	 */
	remove: function(array, element) {
		let index;

		do {
			index = array.indexOf(element);

			if (index !== -1) {
				array.splice(index, 1);
			}
		} while (index !== -1);
	}
}
