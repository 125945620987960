import Window from "./Window";

import contentHtml from "../templates/OurWorksWindow-content.html";

import en from "../texts/OurWorksWindow-en.json";
import ru from "../texts/OurWorksWindow-ru.json";

export default class OurWorksWindow extends Window {
	/**
	 * Creates a new "our works" window.
	 * 
	 * @param {HTMLElement | JQuery<HTMLElement>} parentElement 
	 */
	constructor(parentElement) {
		super(parentElement, { en : en, ru : ru });
		this.getElement().addClass("our-works-window");
		this.setContentHtml(contentHtml);

		let that = this;
		this.getElement().find(".desktop-icon a").click(function(e) {
			e.stopPropagation();
			that.showProject(this.getAttribute("data-project"));
		});
	}

	/**
	 * 
	 * @param {string} project 
	 */
	showProject(project) {
		this.trigger("showProject", project);
	}
}