import EventDispatcher from "./EventDispatcher";

let _element = Symbol("element");
let _localeData = Symbol("localeData");
let _subViews = Symbol("subViews");

/**
 * Base class for everything that displays GUI.
 */
export default class View extends EventDispatcher {
	/**
	 * 
	 * @param {HTMLElement | JQuery<HTMLElement>} element 
	 * @param {object} localeData Keys are locale names; values are locale data for this locale name.
	 * @param {parentView} Parent view (`element` must be inside it); if not set, do not add this to a parent view
	 */
	constructor(element, localeData, parentView) {
		super();
		this[_element] = $(element);
		this[_localeData] = localeData || {};
		this[_subViews] = [];

		if (parentView) {
			parentView[_subViews].push(this);
		}
	}

	/**
	 * Returns the JQuery object for the HTML root element to which this view is bound.
	 * 
	 * @return {JQuery<HTMLElement>}
	 */
	getElement() {
		return this[_element];
	}

	/**
	 * Shows the view.
	 */
	show() {
		this.getElement().show();
		this.trigger("show");
	}

	/**
	 * Hides the view.
	 */
	hide() {
		this.getElement().hide();
		this.trigger("hide");
	}

	/**
	 * Adds the view to a parent element.
	 * 
	 * @param {HTMLElement | JQuery<HTMLElement>} parent parent element to add this view to 
	 */
	addTo(parent) {
		$(parent).append(this.getElement());
		this.trigger("add");
	}

	/**
	 * Removes the view from its parent.
	 */
	remove() {
		this.getElement().remove();
		this.trigger("remove");
	}

	/**
	 * Translates this view into the current locale.
	 * 
	 * @param {string} language the language (two-letter code)
	 */
	onTranslate(language) {
		let localeData = this[_localeData][language];

		if (!localeData) {
			console.log('No locale data for ' + this.constructor.name + ' for language "' + language + '"');
			return;
		}

		/**
		 * @type Array.<View>
		 */
		let subViews = this[_subViews];
		let subViewRoots = subViews.map(subView => subView.getElement());

		let element = this.getElement();
		element.attr("lang", language);
		element.find("[data-generated]").remove();

		element.find("[data-locale]").each(function()  {
			// Filter out localizable elements in sub-views
			for (let subViewRoot of subViewRoots) {
				// Exclude DOM hierarchies from sub-views from localization of this view
				if ($.contains(subViewRoot[0], this)) {
					return; // continue
				}
			}

			let localeValue = localeData[this.getAttribute("data-locale")];
	
			if (!Array.isArray(localeValue)) {
				// single string
				this.innerHTML = localeValue;
			} else {
				let parent = this.parentElement;
				let nextSibling = this.nextSibling; // null if last child of parent
	
				// array of strings
				this.innerHTML = localeValue[0];
	
				for (let i = 1; i < localeValue.length; i++) {
					let newElement = this.cloneNode(true);
					newElement.setAttribute("data-generated", "true");
					newElement.innerHTML = localeValue[i];
					// add all cloned elements after the original
					parent.insertBefore(newElement, nextSibling);
				}
			}
		});

		// Attribute value localization support
		element.find("[data-locale-attrs]").each(function() {
			let attrs = this.getAttribute("data-locale-attrs").split(",");

			for (let attr of attrs) {
				let localeValue = localeData[this.getAttribute("data-locale-" + attr)];
				this.setAttribute(attr, localeValue);
			}
		})

		// Localize sub-views recursively
		for (let subView of subViews) {
			subView.onTranslate(language);
		}
	}
}
